import diff_match_patch from 'diff-match-patch';

const diffString = document.querySelectorAll('.diff__version__property');
const diffCollection = document.querySelectorAll(
    '.diff__version__property_collection',
);

diffString.forEach((property) => {
    compareTexts(property, property.dataset.old, property.dataset.new);
});

diffCollection.forEach((property) => {
    compareArrays(
        property,
        JSON.parse(property.dataset.old),
        JSON.parse(property.dataset.new),
    );
});

function compareTexts(element, text1, text2) {
    const dmp = new diff_match_patch();
    const diffs = dmp.diff_main(text1, text2);
    dmp.diff_cleanupSemantic(diffs);

    element.innerHTML = '';

    diffs.forEach(function (part) {
        const span = document.createElement('span');
        const color = part[0];
        const text = part[1];

        if (color === 1) {
            // Ajouté
            span.className = 'diff__added';
        } else if (color === -1) {
            // Supprimé
            span.className = 'diff__removed';
        }

        span.textContent = text;
        element.appendChild(span);
    });
}

function compareArrays(element, array1, array2) {
    element.innerHTML = '';

    // Trouver les éléments supprimés (présents dans array1 mais pas dans array2)
    array1.forEach((item) => {
        if (!array2.includes(item)) {
            const span = document.createElement('span');
            span.className = 'diff__added';
            span.textContent = item + ' ';
            element.appendChild(span);
        }
    });

    // Trouver les éléments ajoutés (présents dans array2 mais pas dans array1)
    array2.forEach((item) => {
        if (!array1.includes(item)) {
            const span = document.createElement('span');
            span.className = 'diff__added';
            span.textContent = item + ' ';
            element.appendChild(span);
        }
    });

    // Afficher les éléments communs sans modification
    array1.forEach((item) => {
        if (array2.includes(item)) {
            const span = document.createElement('span');
            span.textContent = item + ' ';
            element.appendChild(span);
        }
    });
}
